export default function connectors(Alpine) {
  Alpine.data("connectorUpdate", () => ({
    /**
     * @param {obj}       item
     * @param {string}    item.uuid
     * @param {string}    reconnectUrl
     */
    async reconnect(item, reconnectUrl) {
      const details = this.$modal.details.data;
      details.isProcessingUpdate = true;
      details.data.uuid = item.uuid;

      const response = await fetch(reconnectUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": document.querySelector("[name=csrfmiddlewaretoken]")
            .value,
        },
        body: JSON.stringify(details.data),
      });

      if (response.status !== 200) {
        let errorPayload = null;
        try {
          errorPayload = await response.json();
        } catch (e) {
          // unexpected format of the error response
          window.$silvr.utils.captureException(e);
        }

        // we check the format of the error to be sure this is a string and not an object
        // if the format is not correct we use an empty message so we will fallback on a default HTML message
        const message =
          typeof errorPayload?.error === "string" ? errorPayload.error : "";
        const code = errorPayload?.code ?? 0;

        details.error = { message, code };
        details.isInvalid = true;
        details.isProcessingUpdate = false;
      } else {
        const responseBody = await response.json();

        if (responseBody.redirect_url) {
          window.open(responseBody.redirect_url, "_self");
        } else {
          // refresh
          await this.fetchIntegrations();

          this.$modal.close();

          Alpine.store("toasts").show(
            window.gettext("The account has been successfully updated."),
          );
        }
      }
    },

    showInformationModal(item) {
      this.$modal.open("account_information-modal", {
        details: Object.assign(
          {
            item,
            data: {},
            isInvalid: false,
            isProcessingUpdate: false,
          },
          item.type === "fino"
            ? {
                icon: item.icon_src,
                name: item.name,
                institution_id: item.id,
                redirectUrl: `/integrations/fino/reconnect/${item.uuid}`,
              }
            : {},
        ),
      });
    },
  }));
}
